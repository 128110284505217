<template>
  <div class="users">
    <div class="row margin-0">
      <div class="col-md-12" :class="{'pad-15': !user, 'pad-0': user}">
          <div class="parent margin-bottom-15" v-if="visible_form">
            <div class="parent-body pad-0">
              <div class="row margin-0">
                <div class="col pad-0">
                  <h4 v-if="!payload.id" class="text-center">Add New Coupon</h4>
                  <h4 v-if="payload.id" class="text-center">Update Coupon</h4>
                  <div class="row margin-0 margin-bottom-5">
                    <div class="col-3" v-if="user">
                      <div class="form-group">
                        <label for=""><b>User</b></label>
                        <p>{{user.first_name}} - {{user.user_id}}</p>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-group">
                        <label for=""><b>Coupon Code</b></label>
                        <input type="text" v-model="payload.code" placeholder="Code" class="form-control form-control-sm">
                        <span class="error" v-if="error && error.code">{{error.code}}</span>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-group">
                        <label for=""><b>Description</b></label>
                        <input type="text" v-model="payload.description" placeholder="description" class="form-control form-control-sm">
                        <span class="error" v-if="error && error.description">{{error.description}}</span>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-group">
                        <label for=""><b>Currency</b></label>
                        <select
                          name=""
                          id=""
                          v-model="payload.currency"
                          class="form-control form-control-sm"
                        >
                          <template v-for="(item, idx) in regionsList" :key="idx">
                            <option :value="item.id">{{item.id}}</option>
                          </template>
                        </select>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-group">
                        <label for=""><b>Applicable For</b></label>
                        <select
                          name=""
                          id=""
                          v-model="payload.applicable"
                          class="form-control form-control-sm"
                        >
                          <option value="ALL">ALL</option>
                          <option value="COINS">COINS</option>
                          <option value="MEMBERSHIP">MEMBERSHIP</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-group">
                        <label for=""><b>Discount Type</b></label>
                        <select name="" id="" v-model="payload.discount_type" class="form-control form-control-sm">
                          <option value="AMOUNT">Amount</option>
                          <option value="PERCENTAGE">Percentage</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-group">
                        <label for=""><b>Discount</b></label>
                        <input type="number" v-model="payload.value" placeholder="Value" class="form-control form-control-sm">
                        <span class="error" v-if="error && error.value">{{error.value}}</span>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-group">
                        <label for=""><b>Minimum Order</b></label>
                        <input type="number" v-model="payload.min_order" placeholder="Min Amount" class="form-control form-control-sm">
                        <span class="error" v-if="error && error.min_order">{{error.min_order}}</span>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-group">
                        <label for=""><b>Maximum Discount</b></label>
                        <input type="number" v-model="payload.max_discount" placeholder="Max Amount" class="form-control form-control-sm">
                        <span class="error" v-if="error && error.max_discount">{{error.max_discount}}</span>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-group">
                        <label for=""><b>Maximum Per User</b></label>
                        <input type="number" v-model="payload.max_per_user" placeholder="Max Amount" class="form-control form-control-sm">
                        <span class="error" v-if="error && error.max_per_user">{{error.max_per_user}}</span>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-group">
                        <label for=""><b>Start Date</b></label>
                        <input type="datetime-local" v-model="payload.start_date" class="form-control form-control-sm">
                        <span class="error" v-if="error && error.start_date">{{error.start_date}}</span>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-group">
                        <label for=""><b>Expiry Date</b></label>
                        <input type="datetime-local" v-model="payload.expiry_date" class="form-control form-control-sm">
                        <span class="error" v-if="error && error.expiry_date">{{error.expiry_date}}</span>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-group">
                        <label for=""><b>Status</b></label>
                        <select name="" id="" v-model="payload.status" class="form-control form-control-sm">
                          <option value="ACTIVE">Active</option>
                          <option value="INACTIVE">InActive</option>
                          <option value="EXPIRED">Expired</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-12 text-right">
                      <button v-if="!payload.id" @click="newData()" class="btn btn-success btn-sm margin-right-10">Add New Coupon</button>
                      <button v-if="payload.id" @click="updateData()" class="btn btn-success btn-sm margin-right-10">Update Coupon</button>
                      <button @click="visible_form=false;" class="btn btn-light btn-sm">Cancel</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="parent">
              <div class="parent-head" v-if="!user">
                  <ul>
                      <li @click="tab='active';getdataList()" :class="{'active':tab==='active'}">
                          Active Coupons
                      </li>
                      <li @click="tab='expired';getdataList()" :class="{'active':tab==='expired'}">
                          Expired Coupons
                      </li>
                  </ul>
              </div>
              <div class="parent-body">
                <div class="row margin-0 margin-bottom-5">
                  <div class="col-2 pad-0">
                    <div class="form-group">
                      <label for=""><b>Search Word</b></label>
                      <input type="text" v-model="search_term" placeholder="Search text" class="form-control form-control-sm">
                    </div>
                  </div>
                  <!-- <div class="col-2">
                    <div class="form-group">
                      <label for=""><b>User Type</b></label>
                      <select name="" id="" v-model="user_type" class="form-control form-control-sm">
                        <option value="all">All</option>
                        <option value="superuser">Superuser</option>
                        <option value="customer">customer</option>
                        <option value="deliveryboy">deliveryboy</option>
                      </select>
                    </div>
                  </div> -->
                  <div class="col-2">
                    <div class="form-group">
                      <br>
                      <button @click="getdataList()" class="btn btn-primary btn-sm margin-right-10">Search</button>
                      <button @click="clearFilter()" class="btn btn-light btn-sm">clear</button>
                    </div>
                  </div>
                  <div class="col text-right">
                    <div class="form-group">
                      <br>
                      <button @click="getdataList()" class="btn btn-light btn-sm margin-right-10"><span class="fa fa-refresh"></span></button>
                      <button @click="visible_form=true;resetPayload()" class="btn btn-primary btn-sm">+ Add Coupon</button>
                    </div>
                  </div>
                </div>
                <table class="table sai-table">
                    <thead>
                        <tr class="t-head">
                        <th
                            :class="{'active_sort': sort_by == 'code' || sort_by == '-code'}"
                            @click="sortBy('code')"
                        >
                            COUPON CODE
                            <i
                            :class="{'fa-sort': sort_by != 'code','fa-sort-up': sort_by == 'code','fa-sort-down': sort_by == '-code' }"
                            class="fa float-right"
                            style="position: relative; top: 0px;"
                            />
                        </th>
                        <th>DESCRIPTION</th>
                        <th>APPLICABLE TO</th>
                        <th
                            :class="{'active_sort': sort_by == 'discount_type' || sort_by == '-discount_type'}"
                            @click="sortBy('discount_type')"
                        >
                            TYPE
                            <i
                            :class="{'fa-sort': sort_by != 'discount_type','fa-sort-up': sort_by == 'discount_type','fa-sort-down': sort_by == '-discount_type' }"
                            class="fa float-right"
                            style="position: relative; top: 0px;"
                            />
                        </th>
                        <th
                            :class="{'active_sort': sort_by == 'min_order' || sort_by == '-min_order'}"
                            @click="sortBy('min_order')"
                        >
                            MIN ORDER
                            <i
                            :class="{'fa-sort': sort_by != 'min_order','fa-sort-up': sort_by == 'min_order','fa-sort-down': sort_by == '-min_order' }"
                            class="fa float-right"
                            style="position: relative; top: 0px;"
                            />
                        </th>
                        <th
                            :class="{'active_sort': sort_by == 'max_discount' || sort_by == '-max_discount'}"
                            @click="sortBy('max_discount')"
                        >
                            MAX DISCOUNT
                            <i
                            :class="{'fa-sort': sort_by != 'max_discount','fa-sort-up': sort_by == 'max_discount','fa-sort-down': sort_by == '-max_discount' }"
                            class="fa float-right"
                            style="position: relative; top: 0px;"
                            />
                        </th>
                        <th
                            :class="{'active_sort': sort_by == 'start_date' || sort_by == '-start_date'}"
                            @click="sortBy('start_date')"
                        >
                            START DATE
                            <i
                            :class="{'fa-sort': sort_by != 'start_date','fa-sort-up': sort_by == 'start_date','fa-sort-down': sort_by == '-start_date' }"
                            class="fa float-right"
                            style="position: relative; top: 0px;"
                            />
                        </th>
                        <th
                            :class="{'active_sort': sort_by == 'expiry_date' || sort_by == '-expiry_date'}"
                            @click="sortBy('expiry_date')"
                        >
                            EXPIRY DATE
                            <i
                            :class="{'fa-sort': sort_by != 'expiry_date','fa-sort-up': sort_by == 'expiry_date','fa-sort-down': sort_by == '-expiry_date' }"
                            class="fa float-right"
                            style="position: relative; top: 0px;"
                            />
                        </th>
                        <th
                            :class="{'active_sort': sort_by == 'status' || sort_by == '-status'}"
                            @click="sortBy('status')"
                        >
                            STATUS
                            <i
                            :class="{'fa-sort': sort_by != 'status','fa-sort-up': sort_by == 'status','fa-sort-down': sort_by == '-status' }"
                            class="fa float-right"
                            style="position: relative; top: 0px;"
                            />
                        </th>
                        <th></th>
                        </tr>
                    </thead>
                    <tbody>
                      <template v-if="!loading_list">
                        <template v-for="item in dataList.results" :key="item.id">
                          <tr>
                            <td>
                              {{item.code}}<br>
                              <span v-if="item.user_details">{{item.user_details}}</span>
                            </td>
                            <td>{{item.description}}</td>
                            <td>{{item.currency}} - {{item.applicable}}</td>
                            <td>{{item.discount_type}}</td>
                            <td>{{item.min_order}}</td>
                            <td>{{item.max_discount}}</td>
                            <td>{{$filters.fullDate(item.start_date)}}</td>
                            <td>{{$filters.fullDate(item.expiry_date)}}</td>
                            <td><status :status="item.status" /></td>
                            <td class="pad-0" style="padding: 0px;">
                              <span
                              class="fa fa-pencil edit-icon f-right"
                              style="cursor pointer;padding:5px;"
                              @click="dataEdit(item)"
                              />
                            </td>
                        </tr>
                        </template>
                      </template>
                        <template v-if="loading_list">
                        <tr v-for="i in 6" :key="i">
                            <td v-for="i in 9" :key="i">
                            <div class="loading-card pad-left-30">
                                <div class="block1">
                                <br>
                                <div class="block2"/>
                                <div class="block3"/>
                                </div>
                            </div>
                            </td>
                        </tr>
                        </template>
                        <tr v-if="dataList.results && dataList.results.length>0 && loading_list == false" class="pagin-block">
                          <td colspan="11">
                              <div class="row">
                              <div class="col-md-4">
                                  <label
                                  for
                                  >&nbsp; Showing {{ dataList.start }} to {{ (parseInt(dataList.start - 1) + parseInt(pageSize))>=dataList.count?dataList.count:(parseInt(dataList.start - 1) + parseInt(pageSize)) }} of {{ dataList.count }} Items</label>
                              </div>
                              <div class="col-md-4 text-center">
                                  <button
                                  :disabled="dataList.previous===null"
                                  class="pagin-button"
                                  @click="getdataList(dataList.previous)"
                                  >
                                  <span class="fa fa-chevron-left"/>
                                  </button>
                                  <button
                                  :disabled="dataList.next===null"
                                  class="pagin-button"
                                  @click="getdataList(dataList.next)"
                                  >
                                  <span class="fa fa-chevron-right"/>
                                  </button>
                              </div>
                              <div class="col-md-4 text-right">
                                  <label for>Items per page:</label>
                                  <select v-model="pageSize" class="pagin-select" @change="getdataList()">
                                  <option value="5">5</option>
                                  <option value="10">10</option>
                                  <option value="20">20</option>
                                  <option value="25">25</option>
                                  <option value="100">100</option>
                                  <option value="500">500</option>
                                  <option value="1000">1000</option>
                                  <option value="2000">2000</option>
                                  </select> &nbsp;
                              </div>
                              </div>
                          </td>
                        </tr>
                        <tr v-if="dataList.results && dataList.results.length==0 && loading_list == false" class="pagin-block">
                          <td colspan="9" class="text-center pad-20">
                              <br>
                              <img src="/search-in-list.png" style="max-width:50%;"><br>
                              <h5><b>No results found.</b></h5><br>
                          </td>
                        </tr>
                    </tbody>
                </table>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['user'],
  data() {
    return {
      dataList: {
        results: []
      },
      selectedProduct: null,
      loading_list: false,
      pageSize: 20,
      sort_by: 'created_on',
      search_term: '',
      tab: 'active',
      user_type: 'all',
      regionsList: null,
      payload: {
        user: null,
        code: null,
        description: null,
        discount_type: 'AMOUNT',
        discount: null,
        min_order: null,
        max_discount: null,
        max_per_user: 1,
        start_date: null,
        expiry_date: null,
        status: 'ACTIVE',
      },
      visible_form: false,
      error: {},
    };
  },
  created() {
    this.getdataList();
    this.getRegionsList();
  },
  methods: {
    makeid(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    getRegionsList() {
      const that = this;
      that.$cs.region.list({
        pageSize: 100
      }).then(
        (data) => {
          that.regionsList = data.data.results;
        },
        () => {
          console.log("error");
        }
      );
    },
    getdataList(page=null) {
      const that = this;
      let params = {
        sort: that.sort_by,
        search: that.search_term,
        state: that.tab==='expired'?'EXPIRED':'OTHER'
      }
      if(this.user) {
        params.user = this.user.id;
      }
      that.$cs.coupon
        .list({
            resource: page,
            pageSize: that.pageSize,
            page,
            params 
        })
        .then(
          data => {
            that.dataList = Object.assign(data.data);
          },
          () => {
            console.log("error");
          }
        );
    },
    sortBy(field) {
      if (
        field === this.sort_by ||
        this.sort_by === `-${field}`
      ) {
        if (!this.sort_by.startsWith("-")) {
          this.sort_by = `-${field}`;
        } else {
          this.sort_by = field;
        }
      } else {
        this.sort_by = field;
      }
      this.getdataList();
    },
    clearFilter(){
      this.user_type = 'all';
      this.search_term = '';
      this.getdataList();
    },
    validateData() {
      const that = this;
      that.error = {};
      var proceed = true;
      if (that.payload.code === null || that.payload.code === "") {
        this.error.code = "code is required";
        proceed = false;
      }
      if (that.payload.description === null || that.payload.description === "") {
        this.error.description = "description is required";
        proceed = false;
      }
      if (that.payload.discount_type === null || that.payload.discount_type === "") {
        this.error.discount_type = "discount_type is required";
        proceed = false;
      }
      if (that.payload.min_order === null || that.payload.min_order === "") {
        this.error.min_order = "min_order is required";
        proceed = false;
      }
      if (that.payload.max_discount === null || that.payload.max_discount === "") {
        this.error.max_discount = "max_discount is required";
        proceed = false;
      }
      if (that.payload.start_date === null || that.payload.start_date === "") {
        this.error.start_date = "start_date is required";
        proceed = false;
      }
      if (that.payload.expiry_date === null || that.payload.expiry_date === "") {
        this.error.expiry_date = "expiry_date is required";
        proceed = false;
      }
      return proceed;
    },
    newData() {
      const that = this;
      if(this.validateData()) {
        that.processing = true
        that.$cs.coupon.create({data:that.payload})
        .then(res=> {
          that.getdataList();
          that.error = {};
          that.visible_form = false;
        },
        err=> {
          if(err.response.status === 400) {
            that.error = err.response.data;
          }
        })
      }
    },
    updateData() {
      const that = this;
      if(this.validateData()) {
        that.$cs.coupon.update({
            data: that.payload,
            resource: `${that.$cs.coupon.api}${that.payload.id}/`,
          })
        .then(res=> {
          that.getdataList();
          that.error = {};
          that.visible_form = false;
        },
        err=> {
          if(err.response.status === 400) {
            that.error = err.response.data;
          }
        })
      }
    },
    resetPayload() {
      this.payload = {
        code: null,
        description: null,
        discount_type: 'AMOUNT',
        discount: null,
        min_order: null,
        max_discount: null,
        max_per_user: 1,
        start_date: null,
        expiry_date: null,
        status: 'ACTIVE',
      };
      this.error = {};
      if(this.user) {
        this.payload.user = this.user.id;
        this.payload.code = this.makeid(10);
      }
    },
    dataEdit(item) {
      this.payload =Object.assign({}, item);
      this.visible_form = true;
    }
  }
};
</script>
<style lang="stylus" scoped>
</style>